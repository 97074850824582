<template>
  <form @submit.prevent="saveOrUpdateUser">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="user.name" class="form-control" id="name" required />
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">Correo:</label>
      <input v-model="user.email" class="form-control" id="email" required />
    </div>
    <div class="mb-3">
      <label class="form-label">Plan:</label>
      <select v-model="user.current_plan_id" class="form-select" required>
        <option v-for="plan in plans" :value="plan.id" :key="plan.id">
          {{ plan.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="password" class="form-label"
        >Contraseña:
        <small v-if="user.id" class="text-sm c-text-primary"
          >(Dejar en blanco para no cambiar la contraseña)</small
        >
      </label>
      <input
        v-model="user.password"
        class="form-control"
        id="password"
        :required="!userId"
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors.vue";
import { create, updateUser } from "@/services/users";
import { all } from "@/services/plans";
import { showNotification } from "@/services/notification";
import { find } from "@/services/users";
export default {
  data() {
    return {
      plans: [],
      loading: false,
      errors: {},
      user: {
        name: "",
        email: "",
        password: "",
        current_plan_id: null,
      },
      userId: this.$route.params.id,
    };
  },
  components: {
    CPrimaryButton,
    C422Errors,
  },
  mounted() {
    this.getPlans();
    this.getUser();
  },
  methods: {
    getUser() {
      if (!this.userId) {
        return;
      }
      find(this.userId).then((response) => {
        this.user = response.data;
      });
    },
    getPlans() {
      all()
        .then((response) => {
          this.plans = response.data.sort(
            (plan, plan2) => plan.monthly_price - plan2.monthly_price
          );
        })
        .catch(() => {
          alert("No pudimos obtener los planes");
        });
    },
    saveOrUpdateUser() {
      this.loading = true;
      if (this.userId) {
        this.updateUser();
      } else {
        this.saveUser();
      }
    },
    saveUser() {
      create(this.user)
        .then(() => {
          showNotification(
            200,
            "success",
            "El usuario se ha creado exitosamente"
          );
          this.loading = false;
          this.$router.push("/admin/usuarios");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    updateUser() {
      if (!this.user.password?.length) {
        delete this.user.password;
      }
      updateUser(this.userId, this.user)
        .then(() => {
          showNotification(
            200,
            "success",
            "El usuario se ha actualizado exitosamente"
          );
          this.loading = false;
          this.$router.push("/admin/usuarios");
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>
