<template>
  <form id="newRecord" @submit.prevent="saveOrUpdatePublicacionMunicipal">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="titulo" class="form-label">Titulo:</label>
      <input
        v-model="publicacion_municipal.title"
        class="form-control"
        id="titulo"
        required
      />
    </div>
    <div class="mb-3">
      <label for="state" class="form-label">Estado:</label>
      <select
        @change="getStateCities"
        id="state"
        class="form-select"
        required
        v-model="publicacion_municipal.state_id"
      >
        <option v-for="estado in estados" :value="estado.id" :key="estado.id">
          {{ estado.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="ciudad" class="form-label">Municipio:</label>
      <select
        id="ciudad"
        class="form-select"
        required
        v-model="publicacion_municipal.city_id"
      >
        <option v-for="city in cities" :value="city.id" :key="city.id">
          {{ city.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="giros" class="form-label">Giro:</label>
      <select
        id="giros"
        class="form-select"
        required
        v-model="publicacion_municipal.giro_id"
      >
        <option v-for="giro in giros" :value="giro.id" :key="giro.id">
          {{ giro.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="tipos" class="form-label">Tipo:</label>
      <select
        id="tipos"
        class="form-select"
        required
        v-model="publicacion_municipal.tipo_id"
      >
        <option v-for="tipo in tipos" :value="tipo.id" :key="tipo.id">
          {{ tipo.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="oficialPublishDate" class="form-label"
        >Fecha de publicación oficial:</label
      >
      <input
        v-model="publicacion_municipal.fecha_publicacion_oficial"
        type="date"
        class="form-control"
        id="oficialPublishDate"
        required
      />
    </div>
    <div class="mb-3">
      <label for="fechaEntradaEnVigor" class="form-label"
        >Fecha de entrada en vigor:</label
      >
      <div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="radioDate"
            id="radioDate"
            :value="true"
            v-model="publicacionEnabledDate"
          />
          <label class="form-check-label" for="radioDate">Fecha</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="radioDate"
            id="radioNA"
            :value="false"
            v-model="publicacionEnabledDate"
          />
          <label class="form-check-label" for="radioNA">NA</label>
        </div>
      </div>
      <input
        v-if="publicacionEnabledDate"
        v-model="publicacion_municipal.fecha_entrada_en_vigor"
        type="date"
        class="form-control"
        id="fechaEntradaEnVigor"
        required
      />
    </div>
    <div class="mb-3">
      <label for="paginas" class="form-label">Paginas:</label>
      <input
        type="text"
        v-model="publicacion_municipal.pages"
        class="form-control"
        id="paginas"
        required
      />
    </div>
    <div class="mb-3">
      <label for="palabras_clave" class="form-label">Palabras Clave:</label>
      <textarea
        v-model="publicacion_municipal.keywords"
        class="form-control"
        id="palabras_clave"
        required
      />
    </div>
    <div class="mb-3">
      <label for="link" class="form-label">link:</label>
      <input
        type="text"
        v-model="publicacion_municipal.link"
        class="form-control"
        id="link"
        required
      />
    </div>
    <div class="mb-3">
      <label for="observaciones" class="form-label">Observaciones:</label>
      <textarea
        v-model="publicacion_municipal.observations"
        class="form-control"
        id="observaciones"
        rows="4"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, find, update } from "@/services/publicaciones_municipales";
import { all as allGiros } from "@/services/giros";
import { all as allTipos } from "@/services/tipos";
import { all as allEstados, cities } from "@/services/states";

export default {
  components: {
    CPrimaryButton,
    C422Errors,
  },
  mounted() {
    this.getGiros();
    this.getTipos();
    this.getEstados();
    this.getPublicacionMunicipal();
  },
  data() {
    return {
      estados: [],
      giros: [],
      tipos: [],
      cities: [],
      publicacion_municipal: {
        title: "",
        state_id: "",
        city_id: "",
        giro_id: "",
        tipo_id: "",
        fecha_publicacion_oficial: "",
        fecha_entrada_en_vigor: "",
        pages: "",
        keywords: "",
        link: "",
        observations: "",
      },
      errors: {},
      loading: false,
      publicacionMunicipalId: this.$route.params.id,
      publicacionEnabledDate: true,
    };
  },
  methods: {
    getPublicacionMunicipal() {
      if (this.publicacionMunicipalId) {
        find(this.publicacionMunicipalId)
          .then((response) => {
            this.publicacion_municipal = response.data;
            if (!this.publicacion_municipal.fecha_entrada_en_vigor) {
              this.publicacionEnabledDate = false;
            }
            this.getStateCities();
          })
          .catch(() => {
            alert(`No pudimos cargar la publicacion municipal.`);
          });
      }
    },
    getEstados() {
      allEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los estados");
        });
    },
    getStateCities() {
      cities(this.publicacion_municipal.state_id).then(
        (response) => (this.cities = response.data)
      );
    },
    getGiros() {
      allGiros()
        .then((response) => {
          this.giros = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los giros");
        });
    },
    getTipos() {
      allTipos()
        .then((response) => {
          this.tipos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los tipos");
        });
    },
    saveOrUpdatePublicacionMunicipal() {
      if (!this.publicacionEnabledDate) {
        this.publicacion_municipal.fecha_entrada_en_vigor = null;
      }
      if (this.publicacionMunicipalId) {
        this.updatePublicacionMunicipal();
      } else {
        this.savePublicacionMunicipal();
      }
    },
    savePublicacionMunicipal() {
      create(this.publicacion_municipal).then(() => {
        this.$router.push(`/admin/publicaciones-municipales/`);
      });
    },
    updatePublicacionMunicipal() {
      update(this.$route.params.id, this.publicacion_municipal).then(() => {
        this.$router.push(`/admin/publicaciones-municipales/`);
      });
    },
  },
};
</script>
