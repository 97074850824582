import axios from "@/plugins/Axios";
import { getQueryParams } from "@/shared/methods.vue";

const model = "publicaciones-municipales";

function all(queryParams) {
  const params = getQueryParams(queryParams);
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}?${params}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(publicacion) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, publicacion);
}

function update(id, publicacion) {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/${model}/${id}`,
    publicacion
  );
}

export { all, find, create, remove, update };
