<template>
  <c-card title="Planes">
    <div class="table-responsive">
      <table class="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Característica</th>
            <th class="text-center" v-for="plan in plans" :key="plan.id">
              {{ plan.name }} <br />
              <span class="c-text-primary small">
                {{ plan.annually_price }} MXN / Anual
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="feature in features" :key="`plan-${feature.id}`">
            <td class="text-secondary small fw-bold">
              {{ feature.feature }}
            </td>
            <template v-for="plan in plans" :key="plan.id">
              <td class="text-secondary small text-center">
                {{ feature[plan.name] }}
              </td>
            </template>
          </tr>
          <tr class="text-center">
            <td></td>
            <td v-for="plan in plans" :key="plan.id">
              <a
                class="btn c-btn-primary"
                href="mailto:cumplimientoparatodos@gmail.com"
              >
                Elegir {{ plan.name }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <p><b>NOTAS:</b></p>
    <p class="small mb-0"><b>DOF = Diario Oficial de la Federación</b></p>
    <p class="small">
      <b
        >*Las Funciones de exportación a Excel y visualización estas disponibles
        para ciertos Estados y Municipios, consulte la lista
        <a
          href="https://www.catehe.com.mx/00_info_com_mx/Lista-de-estados-y-municipios.pdf"
          target="_blank"
          >AQUÍ</a
        >
      </b>
    </p>
  </c-card>
</template>

<script>
import { all } from "@/services/plans";
import CCard from "@/components/CCard.vue";
export default {
  name: "Plans",
  components: {
    CCard,
  },
  props: {
    userCurrentPlan: {
      default: {},
      required: true,
    },
  },
  data() {
    return {
      loadingCheckout: false,
      plans: [],
      features: [
        {
          id: 0,
          feature: "Matriz legal",
          Starter: "100 requisitos legales",
          Plus: "Requisitos legales ilimitados",
        },
        {
          id: 1,
          feature: "Visualizar Publicaciones Gacetas Estatales",
          Starter: "Una entidad federativa*",
          Plus: "Todas las entidades federativas*",
        },
        {
          id: 2,
          feature: "Visualizar Publicaciones Municipales",
          Starter: "Un municipio",
          Plus: "Todos los municipios* de todas las entidades federativas",
        },
        {
          id: 3,
          feature: "Formulario para implementar acciones correctivas",
          Starter: "✓",
          Plus: "✓",
        },
        {
          id: 4,
          feature: "Actualización permanente de Matriz legal",
          Starter: "✓",
          Plus: "✓",
        },
        {
          id: 5,
          feature: "Visualizar publicaciones del DOF mas recientes",
          Starter: "✓",
          Plus: "✓",
        },
        /*{
          id: 6,
          feature: "Compra de herramientas premium",
          Starter: "✓",
          Plus: "✓",
        },*/
        {
          id: 7,
          feature: "Exportar a Excel Matriz legal",
          Starter: "x",
          Plus: "✓",
        },
        {
          id: 8,
          feature: "Filtrar y Exportar publicaciones del DOF",
          Starter: "x",
          Plus: "✓",
        },
        {
          id: 9,
          feature:
            "Exportar a Excel publicaciones de entidades Federativas / Municipios*",
          Starter: "x",
          Plus: "✓",
        },
      ],
    };
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      all()
        .then((response) => {
          this.plans = response.data.sort(
            (plan, plan2) => plan.monthly_price - plan2.monthly_price
          );
        })
        .catch(() => {
          alert("No pudimos obtener los planes");
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.table>:not(caption)>*>*
  padding: 0.8rem
</style>
