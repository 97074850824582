<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Editar Publicacion Municipal">
          <publicacion-municipal-form />
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import CCard from "@/components/CCard.vue";
import PublicacionMunicipalForm from "@/components/Forms/PublicacionMunicipalForm";
export default {
  components: {
    CCard,
    PublicacionMunicipalForm,
  },
};
</script>
