<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Crear usuario">
          <usuario-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import UsuarioForm from "@/components/Forms/UsuarioForm.vue";
import CCard from "@/components/CCard.vue";
export default {
  components: {
    UsuarioForm,
    CCard,
  },
};
</script>
