<template>
  <div class="container" v-if="user.id && !userMarcosLegalesEspecificos.length">
    <matriz-options
      @showProfiles="showProfiles = true"
      v-if="user.city_id && !showProfiles"
    />
    <select-country-and-state v-if="!user.city_id" @updatedUser="getUser()" />
    <profiles v-if="showProfiles" />
  </div>
</template>
<script>
import SelectCountryAndState from "@/components/SelectCountryAndState.vue";
import MatrizOptions from "@/components/MatrizOptions.vue";
import Profiles from "@/components/Profiles.vue";
import { user } from "@/services/auth";
import { marcosLegalesEspecificos as userMarcosLegalesEspecificos } from "@/services/users";
import { showNotification } from "../services/notification";

export default {
  components: {
    SelectCountryAndState,
    MatrizOptions,
    Profiles,
  },
  data() {
    return {
      user: {},
      showProfiles: false,
      userMarcosLegalesEspecificos: [{}],
    };
  },
  mounted() {
    this.getUser();
    this.getUserMarcosLegalesEspecificos();
  },
  methods: {
    getUser() {
      user().then((response) => {
        this.user = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
      });
    },
    getUserMarcosLegalesEspecificos() {
      userMarcosLegalesEspecificos()
        .then((response) => {
          this.userMarcosLegalesEspecificos = response.data;
          if (this.userMarcosLegalesEspecificos.length) {
            this.$router.push("/matriz-legal");
          }
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error obteniendo tus datos"
          );
        });
    },
  },
};
</script>
