<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5">
        <div class="my-4 text-center">
          <img src="/img/logo.png" alt="Logo" width="80" />
        </div>
        <c-card title="Inicia sesión">
          <form @submit.prevent="login">
            <c-422-errors :errors="errors" />
            <div class="mb-3">
              <label for="email" class="form-label">Correo:</label>
              <input
                v-model="auth.email"
                data-test="email"
                type="email"
                class="form-control"
                id="email"
                aria-describedby="correoAyuda"
                required
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Contraseña:</label>
              <input
                v-model="auth.password"
                data-test="password"
                type="password"
                class="form-control"
                id="password"
                required
              />
            </div>
            <c-primary-button id="login" class="w-100" :disabled="loading">
              Inicia sesión
            </c-primary-button>
          </form>
          <div class="mt-4 text-center">
            <span
              >¿No tienes cuenta?
              <router-link to="/auth/registro" class="c-text-primary"
                >Regístrate</router-link
              ></span
            >
            <br />
            <router-link
              to="/auth/forgot-password"
              class="c-text-primary mt-2 d-inline-block"
            >
              ¿Olvidaste tu contraseña?</router-link
            >
          </div>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import { setCRSFCookie, login, user, authUserIsAdmin } from "@/services/auth";
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";

export default {
  name: "Login",
  components: {
    CCard,
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      auth: {
        email: "",
        password: "",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {
    setCRSFCookie();
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        this.errors = {};
        await login(this.auth);
        localStorage.setItem("logged", "true");
        user().then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          if (authUserIsAdmin()) {
            window.location.href = "/admin/marcos-legales-generales";
          } else {
            user.city_id
              ? (window.location.href = "/")
              : (window.location.href = "/ayuda-matriz");
          }
        });
      } catch (error) {
        if (!error.response) {
          alert("Tuvimos un problema para iniciar sesión.");
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
  },
};
</script>
