<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <c-card title="Perfil">
          <form @submit.prevent="update" enctype="multipart/form-data">
            <c-422-errors :errors="errors" />
            <div class="mb-3">
              <label for="name" class="form-label">Nombre:</label>
              <input
                v-model="user.name"
                class="form-control"
                id="name"
                required
              />
            </div>
            <div class="mb-3">
              <label for="formFile" class="form-label"
                >Logo centro de trabajo</label
              >
              <input
                class="form-control"
                type="file"
                id="formFile"
                accept="image/*"
              />
            </div>
            <div v-if="user.company_logo" class="w-50 my-3">
              <img :src="user.company_logo" class="img-fluid" alt="Logo" />
            </div>
            <c-primary-button class="w-100" :disabled="loading">
              Guardar
            </c-primary-button>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import CCard from "@/components/CCard";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { user, authUser, setAuthUser } from "@/services/auth";
import { update, updateCompanyLogo } from "@/services/users";
import { showNotification } from "@/services/notification";

export default {
  name: "Profile",
  components: {
    CCard,
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      user: authUser(),
      errors: {},
      loading: false,
    };
  },
  methods: {
    update() {
      update(this.user.id, this.user)
        .then((response) => {
          setAuthUser(response.data);
          showNotification(
            200,
            "success",
            "El nombre se ha actualizado exitosamente"
          );
          this.updateCompanyLogo();
        })
        .catch((error) => {
          showNotification(error);
        });
    },

    updateCompanyLogo() {
      const image = document.getElementById("formFile").files[0];
      if (!image) {
        return;
      }
      const formData = new FormData();
      formData.append("company_logo", image);

      updateCompanyLogo(formData).then(() => {
        user().then((response) => {
          setAuthUser(response.data);
          this.user = response.data;
          showNotification(
            200,
            "success",
            "El logo se ha actualizado exitosamente"
          );
        });
      });
    },
  },
};
</script>

<style scoped></style>
