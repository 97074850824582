<template>
  <div>
    <h3>Configura tu matriz de requisitos legales</h3>
    <p>
      Podemos ayudarte a configurar tu matriz de requisitos legales, selecciona
      alguna de las siguientes opciones:
    </p>
    <div class="mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6 div col-lg-4 mt-3">
          <div
            @click="selectedOption = 'manual'"
            class="card rounded-end-0"
            :class="selectedOption === 'manual' ? 'c-border-primary' : ''"
          >
            <div class="card-body">
              <h4 class="c-text-primary">Configura tu matriz manualmente</h4>
              <p>Selecciona uno o varios perfiles.</p>
              <p>Podrás agregar o quitar requisitos legales posteriormente.</p>
            </div>
            <div
              class="bottom-card p-3 text-center"
              :class="
                selectedOption === 'manual' ? 'c-bg-primary' : 'c-bg-grey'
              "
            >
              <span
                class="
                  icon-container
                  p-1
                  d-inline-flex
                  align-items-center
                  justify-content-center
                "
              >
                <i
                  v-if="selectedOption === 'manual'"
                  class="fa-solid fa-check fa-lg text-white"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 div col-lg-4 mt-3">
          <div
            @click="selectedOption = 'carta'"
            class="card rounded-end-0 h-100"
            :class="selectedOption === 'carta' ? 'c-border-primary' : ''"
          >
            <div class="card-body">
              <h4 class="c-text-primary">Configura a la carta</h4>
              <p>
                Accede a nuestra la biblioteca legal y selecciona los requisitos
                legales que te apliquen.
              </p>
              <p>Podrás agregar o quitar requisitos legales posteriormente.</p>
            </div>
            <div
              class="bottom-card p-3 text-center"
              :class="selectedOption === 'carta' ? 'c-bg-primary' : 'c-bg-grey'"
            >
              <span
                class="
                  icon-container
                  p-1
                  d-inline-flex
                  align-items-center
                  justify-content-center
                "
              >
                <i
                  v-if="selectedOption === 'carta'"
                  class="fa-solid fa-check fa-lg text-white"
                ></i>
                <i class="fa-solid fa-xmark-large"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center my-5">
        <c-primary-button class="w-50" @click="goToBibliotecaLegal">
          Continuar
        </c-primary-button>
      </div>
    </div>
  </div>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
export default {
  components: {
    CPrimaryButton,
  },
  data() {
    return {
      selectedOption: "manual",
    };
  },
  methods: {
    goToBibliotecaLegal() {
      if (this.selectedOption === "manual") {
        this.$emit("showProfiles");
      } else {
        this.$router.push(`/biblioteca-legal?mode=${this.selectedOption}`);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.card
  cursor: pointer
.icon-container
  border: solid 2px white
  border-radius: 50%
  min-height: 35px
  min-width: 35px
.c-bg-grey
  background-color: #ededed
</style>
