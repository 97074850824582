<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-7 col-lg-5">
        <c-card title="Datos">
          <div class="alert alert-info">
            Por favor ingresa tu estado y municipio, esto nos ayudará a darte
            recomendaciones para tu matriz legal.
          </div>
          <form @submit.prevent="updateUser">
            <div class="mb-3">
              <label for="state" class="form-label">Estado:</label>
              <select
                @change="getStateCities"
                id="state"
                class="form-select"
                v-model="selectedStateId"
                required
              >
                <option
                  v-for="state in states"
                  :value="state.id"
                  :key="state.id"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="city" class="form-label">Municipio:</label>
              <select
                id="city"
                class="form-select"
                required
                v-model="user.city_id"
              >
                <option v-for="city in cities" :value="city.id" :key="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>
            <c-primary-button class="w-100 mt-3" type="submit">
              Guardar
            </c-primary-button>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { all as allStates, cities } from "@/services/states";
import { user } from "@/services/auth";
import { update } from "@/services/users";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      states: [],
      selectedStateId: "",
      selectedCityId: "",
      cities: [],
      user: {},
    };
  },
  mounted() {
    this.getUser();
    this.getStates();
  },
  methods: {
    getUser() {
      user().then((response) => {
        this.user = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
      });
    },
    getStates() {
      allStates().then((response) => (this.states = response.data));
    },
    getStateCities() {
      cities(this.selectedStateId).then(
        (response) => (this.cities = response.data)
      );
    },
    updateUser() {
      update(this.user.id, this.user).then(() => {
        showNotification(200, "success", "Hemos actualizado tus datos.");
        this.$emit("updatedUser");
      });
    },
  },
};
</script>
