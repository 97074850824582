<template>
  <div class="container">
    <publicaciones-filters @search="this.getPublicacionesMunicipales" />
    <div class="text-end p-3">
      <json-excel
        :data="PublicacionesMunicipales"
        :fields="publicacionesFields"
        name="publicaciones-municipales"
        class="me-3 d-inline-block"
      >
        <c-primary-button> Descargar </c-primary-button>
      </json-excel>
      <c-primary-button v-on:click="newPublicacionMunicipal">
        Crear Publicacion Municipal
      </c-primary-button>
    </div>
    <c-card title="Publicaciones Municipales">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Año</th>
            <th>Estado</th>
            <th>Municipio</th>
            <th>Titulo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="PublicacionMunicipal in PublicacionesMunicipales"
            :key="PublicacionMunicipal.id"
          >
            <td>
              {{ PublicacionMunicipal.fecha_publicacion_oficial_formatted }}
            </td>
            <td>{{ PublicacionMunicipal.state }}</td>
            <td>{{ PublicacionMunicipal.city }}</td>
            <td>{{ PublicacionMunicipal.title }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/publicaciones-municipales/${PublicacionMunicipal.id}/editar`"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deletePublicacionMunicipal(PublicacionMunicipal.id)"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import PublicacionesFilters from "@/components/PublicacionesFilters.vue";
import JsonExcel from "vue-json-excel3";
import {
  all as AllPublicacionesMunicipales,
  remove,
} from "@/services/publicaciones_municipales";

export default {
  components: {
    CCard,
    CPrimaryButton,
    PublicacionesFilters,
    JsonExcel,
  },
  data() {
    return {
      PublicacionesMunicipales: [],
      publicacionesFields: {
        "Fecha de publicación oficial": "fecha_publicacion_oficial_formatted",
        Estado: "state",
        Municipio: "city",
        Giro: "giro",
        Tipo: "tipo",
        Título: "title",
        "Palabras clave": "keywords",
        Observaciones: "observations",
        "Fecha de aplicación": "fecha_entrada_en_vigor_formatted",
        link: "link",
        páginas: "pages",
      },
    };
  },
  mounted() {
    this.getPublicacionesMunicipales();
  },
  methods: {
    newPublicacionMunicipal() {
      this.$router.push(`/admin/publicaciones-municipales/crear`);
    },
    deletePublicacionMunicipal(id) {
      const confirm = window.confirm(
        `¿Estás seguro de eliminar la Publicacion Municipal?`
      );
      if (confirm) {
        remove(id).then(() => {
          this.getPublicacionesMunicipales();
        });
      }
    },
    getPublicacionesMunicipales() {
      const queryParams = this.$route.query;
      AllPublicacionesMunicipales(queryParams)
        .then((response) => {
          this.PublicacionesMunicipales = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las Publicaciones Municipales");
        });
    },
  },
};
</script>
