<template>
  <div class="container">
    <div class="row justify-content-center">
      <div v-if="passwordUpdated" class="col-md-8 col-lg-5 mt-5">
        <div class="alert alert-success">
          <div class="alert-body">
            Tu contraseña ha sido actualizada, puedes iniciar sesión
            <router-link to="/auth/login" class="c-text-primary">
              aquí.</router-link
            >
          </div>
        </div>
      </div>
      <div v-else class="col-md-8 col-lg-5">
        <div class="my-4 text-center">
          <img src="/img/logo.png" alt="Logo" width="80" />
        </div>
        <c-card title="Cambiar contraseña">
          <form @submit.prevent="resetPassword">
            <c-422-errors :errors="errors" />
            <div class="mb-3">
              <label for="email" class="form-label">Correo:</label>
              <input
                v-model="data.email"
                type="email"
                class="form-control"
                id="email"
                aria-describedby="correoAyuda"
                required
                disabled
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Nueva contraseña:</label>
              <input
                v-model="data.password"
                type="password"
                class="form-control"
                id="password"
                required
              />
            </div>
            <div class="mb-3">
              <label for="repeat-password" class="form-label"
                >Confirmar contraseña:</label
              >
              <input
                v-model="data.password_confirmation"
                type="password"
                class="form-control"
                id="repeat-password"
                required
              />
            </div>
            <c-primary-button class="w-100">
              Cambiar contraseña
            </c-primary-button>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import { resetPassword } from "@/services/auth";
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
export default {
  name: "ResetPassword",
  components: {
    CCard,
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      errors: {},
      data: {
        email: this.$route.query.email,
        password: "",
        password_confirmation: "",
        token: this.$route.params.token,
      },
      passwordUpdated: false,
    };
  },
  methods: {
    async resetPassword() {
      try {
        await resetPassword(this.data);
        this.passwordUpdated = true;
      } catch (error) {
        if (!error.response) {
          alert("Tuvimos un problema para iniciar sesión.");
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
  },
};
</script>

<style scoped></style>
