<template>
  <form id="newRecord" @submit.prevent="saveOrUpdatePublicacionEstatal">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="titulo" class="form-label">Titulo:</label>
      <input
        v-model="publicacion_estatal.title"
        class="form-control"
        id="titulo"
        required
      />
    </div>
    <div class="mb-3">
      <span for="state" class="form-label">Estado:</span>
      <select
        id="state"
        class="form-select"
        required
        v-model="publicacion_estatal.state_id"
      >
        <option v-for="estado in estados" :value="estado.id" :key="estado.id">
          {{ estado.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="giros" class="form-label">Giro:</label>
      <select
        id="giros"
        class="form-select"
        required
        v-model="publicacion_estatal.giro_id"
      >
        <option v-for="giro in giros" :value="giro.id" :key="giro.id">
          {{ giro.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="tipos" class="form-label">Tipo:</label>
      <select
        id="tipos"
        class="form-select"
        required
        v-model="publicacion_estatal.tipo_id"
      >
        <option v-for="tipo in tipos" :value="tipo.id" :key="tipo.id">
          {{ tipo.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="oficialPublishDate" class="form-label"
        >Fecha de publicación oficial:</label
      >
      <input
        v-model="publicacion_estatal.fecha_publicacion_oficial"
        type="date"
        class="form-control"
        id="oficialPublishDate"
        required
      />
    </div>
    <div class="mb-3">
      <label for="fechaEntradaEnVigor" class="form-label"
        >Fecha de entrada en vigor:</label
      >
      <div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="radioDate"
            id="radioDate"
            :value="true"
            v-model="publicacionEnabledDate"
          />
          <label class="form-check-label" for="radioDate">Fecha</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="radioDate"
            id="radioNA"
            :value="false"
            v-model="publicacionEnabledDate"
          />
          <label class="form-check-label" for="radioNA">NA</label>
        </div>
      </div>
      <input
        v-if="publicacionEnabledDate"
        v-model="publicacion_estatal.fecha_entrada_en_vigor"
        type="date"
        class="form-control mt-2"
        id="fechaEntradaEnVigor"
        required
      />
    </div>
    <div class="mb-3">
      <label for="paginas" class="form-label">Paginas:</label>
      <input
        type="text"
        v-model="publicacion_estatal.pages"
        class="form-control"
        id="paginas"
        required
      />
    </div>
    <div class="mb-3">
      <label for="palabras_clave" class="form-label">Palabras Clave:</label>
      <textarea
        v-model="publicacion_estatal.keywords"
        class="form-control"
        id="palabras_clave"
        required
      />
    </div>
    <div class="mb-3">
      <label for="link" class="form-label">link:</label>
      <input
        type="text"
        v-model="publicacion_estatal.link"
        class="form-control"
        id="link"
        required
      />
    </div>
    <div class="mb-3">
      <label for="observaciones" class="form-label">Observaciones:</label>
      <textarea
        v-model="publicacion_estatal.observations"
        class="form-control"
        id="observaciones"
        rows="4"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { find, create, update } from "@/services/publicaciones_estatales";
import { all as allGiros } from "@/services/giros";
import { all as allTipos } from "@/services/tipos";
import { all as allEstados } from "@/services/states";

export default {
  components: {
    CPrimaryButton,
    C422Errors,
  },
  mounted() {
    this.getGiros();
    this.getTipos();
    this.getEstados();
    this.getPublicacionEstatal();
  },
  data() {
    return {
      estados: [],
      giros: [],
      tipos: [],
      publicacion_estatal: {
        title: "",
        state_id: "",
        giro_id: "",
        tipo_id: "",
        fecha_publicacion_oficial: "",
        fecha_entrada_en_vigor: "",
        pages: "",
        keywords: "",
        link: "",
        observations: "",
      },
      errors: {},
      loading: false,
      publicacionEstatalId: this.$route.params.id,
      publicacionEnabledDate: true,
    };
  },
  methods: {
    getPublicacionEstatal() {
      if (this.publicacionEstatalId) {
        find(this.publicacionEstatalId)
          .then((response) => {
            this.publicacion_estatal = response.data;
            if (!this.publicacion_estatal.fecha_entrada_en_vigor) {
              this.publicacionEnabledDate = false;
            }
          })
          .catch(() => {
            alert(`No pudimos cargar la publicacion estatal`);
          });
      }
    },
    getEstados() {
      allEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los estados");
        });
    },
    getGiros() {
      allGiros()
        .then((response) => {
          this.giros = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los giros");
        });
    },
    getTipos() {
      allTipos()
        .then((response) => {
          this.tipos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los tipos");
        });
    },
    saveOrUpdatePublicacionEstatal() {
      if (!this.publicacionEnabledDate) {
        this.publicacion_estatal.fecha_entrada_en_vigor = null;
      }
      if (this.publicacionEstatalId) {
        this.updatePublicacionEstatal();
      } else {
        this.savePublicacionEstatal();
      }
    },
    savePublicacionEstatal() {
      create(this.publicacion_estatal).then(() => {
        this.$router.push(`/admin/publicaciones-estatales/`);
      });
    },
    updatePublicacionEstatal() {
      update(this.$route.params.id, this.publicacion_estatal).then(() => {
        this.$router.push(`/admin/publicaciones-estatales/`);
      });
    },
  },
};
</script>
