<template>
  <div class="container">
    <publicaciones-filters @search="this.getPublicacionesEstatales" />
    <div class="text-end p-3">
      <json-excel
        class="me-3 d-inline-block"
        :data="PublicacionesEstatales"
        :fields="publicacionesFields"
        name="publicaciones-estatales"
      >
        <c-primary-button> Descargar </c-primary-button>
      </json-excel>
      <c-primary-button v-on:click="newPublicacionEstatal">
        Crear Publicacion Estatal
      </c-primary-button>
    </div>
    <c-card title="Publicaciones Estatales">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Fecha de publicación oficial</th>
            <th>Estado</th>
            <th>Titulo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="PublicacionEstatal in PublicacionesEstatales"
            :key="PublicacionEstatal.id"
          >
            <td>
              {{ PublicacionEstatal.fecha_publicacion_oficial_formatted }}
            </td>
            <td>{{ PublicacionEstatal.state }}</td>
            <td>{{ PublicacionEstatal.title }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/publicaciones-estatales/${PublicacionEstatal.id}/editar`"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deletePublicacionEstatal(PublicacionEstatal.id)"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import PublicacionesFilters from "@/components/PublicacionesFilters.vue";
import JsonExcel from "vue-json-excel3";
import {
  all as AllPublicacionesEstatales,
  remove,
} from "@/services/publicaciones_estatales";

export default {
  components: {
    CCard,
    CPrimaryButton,
    PublicacionesFilters,
    JsonExcel,
  },
  data() {
    return {
      PublicacionesEstatales: [],
      publicacionesFields: {
        "Fecha de publicación oficial": "fecha_publicacion_oficial_formatted",
        Estado: "state",
        Giro: "giro",
        Tipo: "tipo",
        Título: "title",
        "Palabras clave": "keywords",
        Observaciones: "observations",
        "Fecha de aplicación": "fecha_entrada_en_vigor_formatted",
        link: "link",
        páginas: "pages",
      },
    };
  },
  mounted() {
    this.getPublicacionesEstatales();
  },
  methods: {
    newPublicacionEstatal() {
      this.$router.push(`/admin/publicaciones-estatales/crear`);
    },
    deletePublicacionEstatal(id) {
      const confirm = window.confirm(
        `¿Estás seguro de eliminar la Publicacion Estatal?`
      );
      if (confirm) {
        remove(id).then(() => {
          this.getPublicacionesEstatales();
        });
      }
    },
    getPublicacionesEstatales() {
      const queryParams = this.$route.query;
      AllPublicacionesEstatales(queryParams)
        .then((response) => {
          this.PublicacionesEstatales = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las Publicaciones Estatales");
        });
    },
  },
};
</script>
