<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Crear Publicacion Estatal">
          <publicacion-estatal-form></publicacion-estatal-form>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import CCard from "@/components/CCard.vue";
import PublicacionEstatalForm from "@/components/Forms/PublicacionEstatalForm";
export default {
  components: {
    CCard,
    PublicacionEstatalForm,
  },
};
</script>
