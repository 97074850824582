<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-7 col-lg-5">
        <c-card title="Perfiles">
          <div class="alert alert-info">
            Selecciona los perfiles que se adaptan a tus necesidades,
            guardaremos en tu matriz todos los marcos legales que se adapten al
            perfil.
          </div>
          <form @submit.prevent="selectProfiles">
            <div
              v-for="profile in profiles"
              class="form-check"
              :key="profile.id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="profile.id"
                :id="profile.id"
                v-model="checkedProfiles"
              />
              <label class="form-check-label" :for="profile.id">
                {{ profile.name }}
              </label>
            </div>
            <c-primary-button class="w-100 mt-3" type="submit">
              Agregar marcos legales
            </c-primary-button>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { authUser } from "@/services/auth";
import { addMarcosLegalesEspecificos } from "@/services/users";
import { all } from "@/services/profiles";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      profiles: [],
      checkedProfiles: [],
      user: authUser(),
    };
  },
  mounted() {
    this.getProfiles();
  },
  methods: {
    getProfiles() {
      all().then((response) => (this.profiles = response.data));
    },
    selectProfiles() {
      const allMLEsIDs = [];

      const checkedProfiles = [];
      this.profiles.forEach((profile) => {
        const selectedProfile = this.checkedProfiles.find(
          (checkedProfile) => checkedProfile === profile.id
        );
        if (selectedProfile) {
          checkedProfiles.push(profile);
        }
      });

      checkedProfiles.forEach((profile) => {
        const ids = profile.marcos_legales_especificos.map((mle) => mle.id);
        allMLEsIDs.push(...ids);
      });

      addMarcosLegalesEspecificos(allMLEsIDs)
        .then(() => {
          showNotification(
            "200",
            "success",
            "Se agregaron exitosamente los marcos legales del perfil"
          );
          this.$router.push(`/matriz-legal`);
        })
        .catch((error) => {
          let errorMessage = "Tuvimos un error para asignarte el registro";
          if (error.response.status === 400) {
            errorMessage =
              "Alcanzaste el límite de líneas, actualiza tu plan para acceder a más líneas";
          }
          showNotification(error.status, "danger", `${errorMessage} ${error}`);
        });
    },
  },
};
</script>
