<template>
  <div class="container">
    <div class="row justify-content-center">
      <div v-if="emailSent" class="col-md-8 col-lg-5 mt-5">
        <div class="alert alert-success">
          <div class="alert-body">
            Hemos enviado un correo con las instrucciones para cambiar tu
            contraseña.
          </div>
        </div>
      </div>
      <div v-else class="col-md-8 col-lg-5">
        <div class="my-4 text-center">
          <img src="/img/logo.png" alt="Logo" width="80" />
        </div>
        <c-card title="Cambio de contraseña">
          <form @submit.prevent="forgotPassword">
            <div class="alert alert-info">
              <div class="alert-body">
                Ingresa tu correo y te enviaremos instrucciones para restablecer
                tu contraseña.
              </div>
            </div>
            <c-422-errors :errors="errors" />
            <div class="mb-3">
              <label for="email" class="form-label">Correo:</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="email"
                aria-describedby="correoAyuda"
                required
              />
            </div>
            <c-primary-button class="w-100"> Enviar correo </c-primary-button>
          </form>
          <div class="mt-4 text-center">
            <span
              >¿Ya tienes cuenta?
              <router-link to="/auth/login" class="c-text-primary"
                >Inicia sesión</router-link
              ></span
            >
            <br />
            <span
              >¿No tienes cuenta?
              <router-link
                to="/auth/registro"
                class="d-inline-block c-text-primary mt-2"
                >Regístrate</router-link
              ></span
            >
          </div>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import { setCRSFCookie, forgotPassword } from "@/services/auth";
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";

export default {
  name: "ForgotPassword",
  components: {
    CCard,
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      email: "",
      errors: {},
      emailSent: false,
    };
  },
  mounted() {
    setCRSFCookie();
  },
  methods: {
    async forgotPassword() {
      try {
        this.errors = {};
        await forgotPassword({ email: this.email });
        this.emailSent = true;
      } catch (error) {
        console.log(error);
        if (!error.response) {
          alert("Tuvimos un problema para enviar el correo.");
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
