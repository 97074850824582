<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5">
        <div class="my-4 text-center">
          <img src="/img/logo.png" alt="Logo" width="80" />
        </div>
        <c-card title="Crear cuenta">
          <form @submit.prevent="register">
            <c-422-errors :errors="errors" />
            <div class="mb-3">
              <label for="name" class="form-label">Nombre:</label>
              <input
                v-model="registerData.name"
                type="text"
                class="form-control"
                id="name"
                required
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Correo:</label>
              <input
                v-model="registerData.email"
                type="email"
                class="form-control"
                id="email"
                required
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Contraseña:</label>
              <input
                v-model="registerData.password"
                type="password"
                class="form-control"
                id="password"
                required
              />
            </div>
            <div class="mb-3">
              <label for="repeat-password" class="form-label"
                >Repetir contraseña:</label
              >
              <input
                v-model="registerData.password_confirmation"
                type="password"
                class="form-control"
                id="repeat-password"
                required
              />
            </div>
            <c-primary-button class="w-100" :disabled="loading">
              Regístrate
            </c-primary-button>
            <div class="mt-4 text-center">
              ¿Ya tienes cuenta?
              <router-link to="/auth/login" class="c-text-primary"
                >Inicia sesión</router-link
              >
            </div>
          </form>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import { setCRSFCookie, register, user, setAuthUser } from "@/services/auth";
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";

export default {
  name: "Register",
  components: {
    CCard,
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      registerData: {
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
      },
      errors: {},
      loading: false,
    };
  },
  mounted() {
    setCRSFCookie();
  },
  methods: {
    async register() {
      this.loading = true;
      try {
        this.errors = {};
        await register(this.registerData);
        localStorage.setItem("logged", "true");
        await this.getAuthUser();
        window.location.href = "/";
      } catch (error) {
        if (!error.response) {
          alert("Tuvimos un problema para iniciar sesión.");
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
    async getAuthUser() {
      const authUser = await user();
      setAuthUser(authUser.data);
    },
  },
};
</script>
