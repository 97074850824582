<template>
  <div class="container">
    <c-card title="Suscripción">
      <!-- <div class="alert alert-warning" role="alert">
        Es común que los bancos bloqueen cargos de páginas donde no sueles
        comprar. Para efectuar cualquier pago recomendamos usar una tarjeta
        digital, esto reducirá la probabilidad de que tu banco evite que se haga
        el cargo.
      </div>-->
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <div class="card rounded-0">
            <!-- al parecer nunca se muestra el contenido del v-if (solo es una sospecha) -->
            <div v-if="plan.is_active" class="card-body">
              <h6 class="card-subtitle mb-2">Plan actual dsds</h6>
              <p class="m-0">{{ plan.name }} ---</p>
              <p>
                {{ plan.amount / 100 }}{{ plan.currency }}/{{ plan.interval }}
              </p>
              <c-primary-button @click="cancelPlan" class="w-100">
                Cancelar plan
              </c-primary-button>
            </div>
            <div v-else class="card-body">
              <div v-if="dbPlan.name">
                <h6 class="card-subtitle mb-2">Plan actual</h6>
                <p class="m-0">{{ dbPlan.name }}</p>
                <!-- <p class="m-0">{{ dbPlan.monthly_price }} MXN</p> -->
              </div>
              <p v-else class="m-0">
                No tienes una suscripción activa, contacta al administrador para
                que se te pueda habilitar una.
              </p>
            </div>
          </div>
        </div>
      </div>
    </c-card>
    <plans :userCurrentPlan="plan" />
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { DBPlan } from "@/services/users";
import { showNotification } from "@/services/notification";
import Plans from "@/components/Plans";
import CPrimaryButton from "@/components/CPrimaryButton.vue";

export default {
  components: {
    CCard,
    Plans,
    CPrimaryButton,
  },
  data() {
    return {
      loader: true,
      plan: {},
      dbPlan: {},
    };
  },
  mounted() {
    this.getDBPlan();
  },
  methods: {
    getDBPlan() {
      DBPlan()
        .then((response) => {
          this.dbPlan = response.data;
          if (this.dbPlan.name) {
            localStorage.setItem("hasPlan", "true");
          }
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error obteniendo tu suscripción"
          );
        });
    },
  },
};
</script>
